export default {
  name: 'UserProfile',
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toogle () {
      this.payment.showUserProfile = !this.payment.showUserProfile
    },
  },
}
